body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-family: Arial, Helvetica, sans-serif;
}

a {
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
}

.pageLayout {
    margin-top: 60px;
    margin-bottom: 40px;
}
